import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: `home`, loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: `policy`, loadChildren: () => import('./pages/policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: `terms`, loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: `email/active/:token`, loadChildren: () => import('./pages/emailvalidate/emailvalidate.module').then(m => m.EmailvalidateModule)
  },
  {
    path: `news`, loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule)
  },
  { path: ``, pathMatch: `full`, redirectTo: `home` },
  { path: `**`, redirectTo: 'home' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
